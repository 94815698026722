import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import { PAGES, BASE_URL } from 'config';
import { Layout } from 'containers';

const ContactThanksPage = () => (
  <Layout page="contact-thank-you">

    <Helmet
      title="Thank You! We'll be in Touch Soon | Haka Tours, New Zealand"
      meta={[
        {
          name: 'description', content: `At Haka Tours we aim to be very respondive to your questions.
          Our team are available during NZ business hours and will get back to you within 24 hours.`,
        },
        { name: 'og:title', content: 'Thank You! We’ll be in Touch Soon | Haka Tours, New Zealand' },
        {
          name: 'og:description', content: `At Haka Tours we aim to be very respondive to your questions.
          Our team are available during NZ business hours and will get back to you within 24 hours.`,
        },
        { name: 'robots', content: 'noindex' },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/contact-thank-you/` }]}
    />

    <section className="l-section">
      <div className="l-container l-container--small u-text--center">
        <h1 className="c-heading c-heading--h1">Thanks!</h1>
        <p>We’ll reply to your enquiry within two business days. <br />
          Add our address to your safe sender list and you’ll never miss a message from us.
          If you haven’t heard from us we could be hiding in your spam folder so don’t forget to check!
        </p>
        <br />
        <br />
        <p>
          <Link to={PAGES.ABOUT_FAQS.PATH} className="c-button c-button--primary c-button--wide">
            Read all our FAQ’s
          </Link>
          <br />
          <br />
          <Link to={PAGES.TOURS.PATH} className="c-button c-button--primary c-button--wide">
            View Tours
          </Link>
        </p>
      </div>
    </section>
  </Layout>
);

export default ContactThanksPage;
